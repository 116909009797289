<template>
	<div class="supplier">
		<h2>To learn more about becoming a Supplier Partner please fill out the form below.</h2>

		<form class="form">
			<div class="form-group">
				<label>To</label>
				<input
					type="text"
					size="26"
					maxlength="26"
					class="text"
					style="cursor: auto"
					disabled
					v-model="supplierForm.to"
				/>
			</div>
			<div class="form-group">
				<label>Name*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.name"
				/>
			</div>
			<div class="form-group">
				<label>Email*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.email"
				/>
			</div>
			<div class="form-group">
				<label>Company Name*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.company"
				/>
			</div>
			<div class="form-group">
				<label>Title*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.title"
				/>
			</div>
			<div class="form-group">
				<label>Address 1*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.address1"
				/>
			</div>
			<div class="form-group">
				<label>Address 2</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.address2"
				/>
			</div>
			<div class="form-group">
				<label>Address 3</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.address3"
				/>
			</div>
			<div class="form-group">
				<label>City*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.city"
				/>
			</div>
			<div class="form-group">
				<label>State*</label>
				<input
					type="text"
					size="10"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.state"
				/>
			</div>
			<div class="form-group">
				<label>Zip Code*</label>
				<input
					type="text"
					size="20"
					maxlength="20"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.zipCode"
				/>
			</div>
			<div class="form-group">
				<label>Country*</label>
				<input
					type="text"
					size="10"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.country"
				/>
			</div>
			<div class="form-group">
				<label>Phone Number*</label>
				<input
					type="text"
					size="20"
					maxlength="20"
					class="text"
					style="cursor: auto"
					v-model="supplierForm.phone"
				/>
			</div>
			<div class="form-group">
				<label style="align-self: flex-start">Message*</label>
				<textarea style="width: 426px; height: 200px" class="text" v-model="supplierForm.message" />
			</div>

			<p style="float: left; clear: left; margin-top: 1rem">* Indicates that the field is required.</p>
			<button type="button" class="form-btn" style="float: right; margin-top: 1rem" @click="submit()">
				Send
			</button>
		</form>
		<Modal v-if="formError" title="Error" @close="formError = false">
			<template v-slot:content>
				<ul>
					<template v-for="(error, i) in errors">
						<li :key="i">{{ error }}</li>
					</template>
				</ul>
			</template>
		</Modal>
		<Modal v-if="showResponseModal" title="Response" @close="showResponseModal = false">
			<template v-slot:content>
				<ul>
					<template v-for="(message, i) in responseMessages">
						<li :key="i">{{ message }}</li>
					</template>
				</ul>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
	name: "SupplierPartner",
	components: {
		Modal,
	},
	data() {
		return {
			supplierForm: {
				to: "ChemFormation Customer Service",
				name: "",
				email: "",
				company: "",
				title: "",
				address1: "",
				address2: "",
				address3: "",
				city: "",
				state: "",
				zipCode: "",
				country: "",
				phone: "",
				message: "",
			},
			errors: [],
			formError: false,
			showResponseModal: false,
			responseMessages: [],
		};
	},
	methods: {
		async submit() {
			let self = this;
			this.responseMessages = [];

			//validate form
			this.validateForm();

			try {
				if (!this.formError) {
					//send api post request
					await this.$http.post(`/api/vendor/request-partner`, this.supplierForm).then(function () {
						self.responseMessages.push("Your request has been recieved and is pending a response.");
						self.showResponseModal = true;
					});
				}
			} catch (error) {
				if (error.response.data) {
					error.response.data.forEach(function (item) {
						self.responseMessages.push(item);
					});
					this.showResponseModal = true;
				}
			}
		},
		validateForm() {
			this.errors = [];

			if (!this.supplierForm.name) {
				this.errors.push("Name is mandatory");
			}

			if (!this.supplierForm.email) {
				this.errors.push("Email is mandatory");
			}

			if (!this.supplierForm.company) {
				this.errors.push("Company Name is mandatory");
			}

			if (!this.supplierForm.title) {
				this.errors.push("Title is mandatory");
			}

			if (!this.supplierForm.address1) {
				this.errors.push("Address 1 is mandatory");
			}

			if (!this.supplierForm.city) {
				this.errors.push("City is mandatory");
			}

			if (!this.supplierForm.state) {
				this.errors.push("State is mandatory");
			}

			if (!this.supplierForm.zipCode) {
				this.errors.push("Zip Code is mandatory");
			}

			if (!this.supplierForm.country) {
				this.errors.push("Country is mandatory");
			}

			if (!this.supplierForm.phone) {
				this.errors.push("Phone is mandatory");
			}

			if (!this.supplierForm.message) {
				this.errors.push("Please provide a message.");
			}

			if (this.errors.length > 0) {
				this.formError = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.supplier {
	display: inline-block;

	h2 {
		margin: 1.5rem 0 1rem;
	}
}
</style>
