<template>
	<div class="modal-overlay">
		<div class="modal">
			<div class="modal-title-bar">
				<span>
					{{ title }}
				</span>
				<button type="button" @click="$emit('close')">
					<v-icon>mdi-close-thick</v-icon>
				</button>
			</div>

			<div v-if="$slots.content" class="modal-content">
				<slot name="content" />
			</div>

			<div class="modal-footer">
				<button type="button" class="modal-footer-action" @click="$emit('close')">Close</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Modal",
	props: {
		title: {
			type: String,
			default: "",
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	height: auto;
	width: 900px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	// border: 1px solid #a6c9e2;
	border: 1px solid #1a1a1a;
	border-radius: 5px;
	// background: #fcfdfd url("~@/assets/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
	color: #222;
	z-index: 101;
	overflow: hidden;

	&-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#aaa, 0.3);
		z-index: 100;
	}

	&-title-bar {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		// border: 1px solid #4297d7;
		// background: #5c9ccc url("~@/assets/ui-bg_gloss-wave_55_5c9ccc_500x100.png") 50% 50% repeat-x;
		background: #fff;
		border: 1px solid #1a1a1a;
		color: #fff;
		font-weight: bold;

		span {
			white-space: nowrap;
			margin-left: 1rem;
		}

		button {
			display: inline-block;
			margin-left: auto;
			width: 20px;
			height: 20px;
			padding: 1px;
			// border: 1px solid #c5dbec;
			border: 1px solid #1a1a1a;
			border-radius: 5px;
			// background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
			font-weight: bold;
			// color: #2e6e9e;
			color: #1a1a1a;

			i {
				font-size: 1.6rem;
				// color: #6da8d5;
				color: #1a1a1a;
			}
		}
	}

	&-content {
		padding: 0.5rem 1rem;
	}

	&-footer {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		padding: 0.5rem 1rem;
		// border-top: 1px solid #c5dbec;
		border-top: 1px solid #1a1a1a;
		background: #fff;

		&-action {
			display: inline-block;
			margin-left: auto;
			// border: 1px solid #a6c9e2;
			border-top: 1px solid #1a1a1a;
			border-radius: 5px;
			// background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
			// color: #2e6e9e;
			color: #1a1a1a;
			font-size: 1rem;
			padding: 0.1rem 0.5rem;
		}
	}
}
</style>
